<template>
    <ul class="atbd-breadcrumb nav">
        <li class="atbd-breadcrumb__item">
        <router-link to="/">
            <span class="la la-home"></span>
        </router-link>
        <span class="breadcrumb__seperator">
            <span class="la la-slash bread-item"></span>
        </span>
        </li>
        <li class="atbd-breadcrumb__item" v-for="(level, k) in inLevels" :key="k">
            <span class="bread-item" style="color: #9299b8;"
                >{{level}}</span
            >
            <span class="breadcrumb__seperator">
                <span class="la la-slash bread-item"></span>
            </span>
        </li>
        <li class="atbd-breadcrumb__item">
        <span class="bread-item">{{lastLevel}}</span>
        </li>
    </ul>
</template>
<script>
export default {
    data(){
        return{
            lastLevel: '',
            inLevels:[]
        }
    },
    props:['levels'],
    created(){
       
        const levels = [...this.levels]
        this.lastLevel = levels.pop()
        this.inLevels = levels
            
    }
}
</script>