<template>
  <div class="contents">
    <div class="profile-setting">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-12 mb-2 sticky">
            <div
              class="breadcrumb-main p-3"
              style="background-color: #f4f5f7 !important"
            >
              <h4 class="text-capitalize breadcrumb-title">
                My Questions
                <Breadcrumb :levels="['Questions', 'View']" />
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid overlay-clipped">
        <LoaderOverlay
          :active="true"
          v-if="!asyncLoader && loadingQuestions"
          :zIndex="99"
          position="absolute"
          style="height: 100%"
        />
        <div class="row">
          <div class="col-lg-12">
            <div
              class="
                userDatatable
                orderDatatable
                global-shadow
                border
                py-30
                px-sm-30 px-20
                bg-white
                radius-xl
                w-100
                mb-30
              "
            >
              <div
                class="
                  project-top-wrapper
                  d-flex
                  justify-content-between
                  flex-wrap
                  mb-25
                  mt-n10
                "
              >
              <div class="d-flex w-100p align-items-center justify-content-center flex-wrap">
                  <div class="project-search order-search global-shadow mt-10">
                    <div class="order-search__form">
                      <i class="las la-search"></i>
                      <input
                        v-model="searchValue"
                        class="form-control mr-sm-2 border-0 box-shadow-none"
                        type="search"
                        placeholder="Filter by description"
                        aria-label="Search"
                      />
                    </div>
                  </div>
                      <span class="ml-3">Status:</span>
                    <div class="project-tap order-project-tap global-shadow">
                      <ul class="nav px-1" id="ap-tab" role="tablist">
                        <li class="nav-item">
                          <a
                            @click="loadQuestion(allQuestionUrl, '&fetch=all')"
                            class="nav-link active"
                            id="ap-overview-tab"
                            data-toggle="pill"
                            href="#timeline1"
                            role="tab"
                            aria-controls="ap-overview"
                            aria-selected="true"
                            >All</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            @click="loadQuestion(qUrl, `&status=0&level=2048`)"
                            class="nav-link"
                            id="timeline-tab"
                            data-toggle="pill"
                            href="#timeline2"
                            role="tab"
                            aria-controls="timeline"
                            aria-selected="false"
                            >In Review</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            @click="loadQuestion(qUrl, `&status=1&level=2048`)"
                            class="nav-link"
                            id="activity-tab"
                            data-toggle="pill"
                            href="#timeline3"
                            role="tab"
                            aria-controls="activity"
                            aria-selected="false"
                            >Approved</a
                          >
                        </li>
                      </ul>
                    </div>
                   <div class="flex-1"></div>
                    <page-size @sizeChange="sizeChange" />

              </div>
                
                <!-- End: .d-flex -->
                <div class="content-center mt-10"></div>
                <!-- End: .content-center -->
              </div>
              <!-- End: .project-top-wrapper -->
              <div class="tab-content" id="ap-tabContent">
                <no-content v-if="noData" />
                
                <MyQuestionsTable
                  :questions="filteredQuestions"
                  :apiQuestions="questions"
                  :paginationData="paginationData"
                  :id="id"
                  :topics="topicsList"
                  :headers="headers"
                  :search="searchValue"
                  v-if="questions.length"
                />
              </div>
            </div>
            <!-- End: .userDatatable -->
          </div>
          <!-- End: .col -->
        </div>
      </div>
      <Pagination
        :url="qUrl"
        :urlParam="urlParam"
        :size="size"
        @pageChange="pageChange"
        @loading="loading"
      />
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import { authComputed } from "@/vuex/helpers.js";
import MyQuestionsTable from "@/components/MyQuestionsTable.vue";
import NoContent from "@/components/NoContent.vue";
import LoaderOverlay from "@/components/LoaderOverlay";
import { mapState } from "vuex";
import Breadcrumb from "../../components/Breadcrumb.vue";
import PageSize from '../../components/PageSize.vue';
export default {
  name: "account-questions",
  components: {
    MyQuestionsTable,
    LoaderOverlay,
    Pagination,
    Breadcrumb,
    NoContent,
    PageSize,
  },
  data() {
    return {
      loadingQuestions: true,
      questions: [],
      totalPage: 0,
      currentPage: 0,
      size: 10,
      id: 1,
      statusCode: "",
      searchValue: "",
      headers: "Status",
      allQuestionUrl: `/v1/office/assessment/question/fetch/user/${this.$store.state.user.ulid}`,
      qUrl: `v1/office/assessment/question/fetch/ustate`,
      // questionUrl: `/v1/office/assessment/question/fetch/user/${this.$store.state.user.ulid}`,
      urlParam: "&fetch=all",
      noData: false,
      paginationData: "",
      topicsList:{}
    };
  },
  metaInfo: {
    title: "Greater Favour",
    titleTemplate: "%s | Questions",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
  computed: {
    ...authComputed,
    ...mapState(["asyncLoader"]),
    filteredQuestions() {
      return this.questions.filter((question) => {
        return question.status === this.statusCode && question.level;
      });
    },
  },
  beforeMount() {
    if (this.loggedIn == false) {
      this.$router.push({ path: "/login" });
    }
  },
  methods: {
    loadQuestion(url, params) {
      this.questionUrl = url;
      this.urlParam = params;
    },
    sizeChange(size){
      this.size = size
    },
    pageChange(dataSet, paginationData, res) {
      this.questions = dataSet;
      this.topicsList = res.info.topics
      this.paginationData = paginationData;
      if (this.questions.length == 0) {
        this.noData = true;
      } else {
        this.noData = false;
      }
    },
    loading(status) {
      this.loadingQuestions = status;
    },
    setStatus(id, tab) {
      this.statusCode = id;
      this.id = tab;
      if (this.id == 1) {
        this.headers = "Status";
      } else {
        this.headers = "";
      }
    },
  },
};
</script>

<style scoped>
.bread-item:hover {
  color: #9299b8 !important;
}
</style>
