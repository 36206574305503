<template>
    <div class="tab-pane fade show active" :id="`timeline${id}`" role="tabpanel" aria-labelledby="ap-overview-tab">
       
        <!-- Start Table Responsive -->
        <div class="table-responsive">
            <table class="table mb-0 table-hover table-borderless border-0">
                <thead>
                    <tr class="userDatatable-header">
                        <th>
                            <span class="userDatatable-title">No</span>
                        </th>
                        <th>
                            <span class="userDatatable-title">Questions</span>
                        </th>
                        <th>
                            <span class="userDatatable-title">Type</span>
                        </th>
                        <th>
                            <span class="userDatatable-title">Version</span>
                        </th>
                        <th v-if="headers">
                            <span class="userDatatable-title">{{headers}}</span>
                        </th>
                        <th>
                            <span class="userDatatable-title float-right">Actions</span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(question, k) in filteredList" :key="k">
                        
                        <td>
                            <div>
                                {{paginationData.offset+k+1}}
                            </div>
                        </td>
                        <td>
                            <div class="orderDatatable-title" v-html="question.description.substring(0,61)+'<br>'+question.description.substring(61,question.description.length)">
                                
                            </div>
                        </td>
                        <td>
                            <div v-if="question.type == 2" class="userDatatable-content">
                                Simple
                            </div>
                            <div v-else-if="question.type == 1" class="userDatatable-content">
                                Dichotomous
                            </div>
                            <div v-else-if="question.type == 4" class="userDatatable-content">
                                Multi
                            </div>
                            <div v-else-if="question.type == 8" class="userDatatable-content">
                                Rank
                            </div>
                            <div v-else-if="question.type == 16" class="userDatatable-content">
                                Open
                            </div>
                        </td>
                        <td>
                            <div class="userDatatable-content d-inline-block">
                                <span v-if="question.version" class="bg-opacity-primary color-primary rounded-pill userDatatable-content-status active">{{question.version}}</span>
                            </div>
                        </td>
                        <td v-if="headers">
                            <div v-if="question.status == 1" class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-success  text-success rounded-pill active">Approved</span>
                            </div>
                            <div v-if="question.status == 0 && question.level" class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-warning  text-warning rounded-pill active">Awaiting Approval</span>
                            </div>
                            <div v-else-if="question.status == 0 && !question.level" class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-info  text-info rounded-pill active">Pending</span>
                            </div>
                            <div v-if="question.status == -1" class="orderDatatable-status d-inline-block">
                                <span class="order-bg-opacity-danger  text-danger rounded-pill active">Disapproved</span>
                            </div>
                        </td>
                        <td>
                            <ul class="orderDatatable_actions mb-0 d-flex flex-wrap float-right">
                                    <li>
                                        <a class="link" @click="questionAdd(question, topicName(question.tolid), true)" v-tooltip:bottom="'Edit Question'">
                                            <i class="las la-edit"></i>
                                        </a>
                                    </li>
                                    <li :class="{'el-fade disabled': question.level != 2048}">
                                        <a class="link" @click="questionView(question, true, question.level != 2048)" v-tooltip:bottom="'Review Question'">
                                            <i class="las la-door-open"></i>
                                        </a>
                                    </li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <!-- Table Responsive End -->
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    props: ['questions', 'id', 'headers', 'apiQuestions', 'search', 'isLoading', 'paginationData', 'topics'],
    computed: {
        ...mapGetters(['allTopics']),
        tableData() {
            return this.id === 1 ? this.apiQuestions : this.questions;
        },
        filteredList() {
            return this.tableData.filter(question => {
                return question.description.toLowerCase().includes(this.search.toLowerCase());
            })
        },
    },
    methods:{
        menuContext(e, q){

            if(q.level != 2048){
                e.stopPropagation();
                
            }
        },
        questionView(question, newTab, disabled){
            if(disabled) return
            localStorage.setItem("topicName",this.topicName(question.tolid))
            localStorage.setItem("currentQlid",question.qlid)
            localStorage.setItem("qLevel",{level: question.level, status:question.status})

            
            this.routeTo(question.level == 2048 && 'questions-reviews-overview', newTab)
        },
        questionAdd(question, topic, newTab){

            const params =  {
                qlid: question.qlid,
                ulid: question.ulid,
                clsId: question.cllid,
                tname: topic,
                url: 'url',
            }

            localStorage.setItem("questionMeta", JSON.stringify(params))

            this.routeTo('classes-topics-questions-add', newTab)
        },
        routeTo(name, newTab){
             let route = this.$router.resolve({name});
             if(newTab){
                 window.open(route.href, '_blank');
             }else{
                 this.$router.push(route.href)
             }
             
        },
        topicName(tolid){
 
            return this.topics[tolid]?.name ? this.topics[tolid].name : ''
        },
    }
}
</script>

<style>
.tooltip-inner {
  background-color: black;
  color: #fff;
}

.tooltip.top .tooltip-arrow {
  border-top-color: black;
}

.tooltip.right .tooltip-arrow {
  border-right-color: black;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-bottom-color: #000000;
  border-width: 0 5px 5px;
}

.tooltip.left .tooltip-arrow {
  border-left-color: black;
}
</style>